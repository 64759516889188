import { html } from 'lit';
import { styles } from "./BmgFeatScannerSerialNumber.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import "../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { BmgFeatScannerElementBase, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import { SerialNumberMapperService } from "../../services/serial-number-mapper.service";
import { CheckDigitValidationService } from "../../services/check-digit-validation-service";
import { CrontoHardwareTokenService } from "../../services/cronto-hardware-token.service";
import { crontoTypes } from "../../model/cronto-type";
import { activationHelpDialogModel } from "../../model/help-dialog-model";
import { AmwaMaskedInput } from "../../../components/amwa-input/AmwaMaskedInput";
import {
  BmgFeatScannerCrontoImage,
  CRONTO_IMAGE_EXPIRED_EVENT_ID
} from "../../components/cronto-image/BmgFeatScannerCrontoImage";

const INVALID_SERIALNUMBER_FORMAT_ERROR_MESSAGE = "Scan the color code again or find the serial number on the back of your device.";
const INTERNAL_SERVER_ERROR_MESSAGE = "A technical error occurred, please try again.";

export class BmgFeatScannerSerialNumber extends BmgFeatScannerElementBase {

  static get is() {
    return 'bmg-feat-scanner-serial-number';
  }

  static get scopedElements() {
    return {
      "amwa-masked-input": AmwaMaskedInput,
    };
  }

  static get properties() {
    return {
      userId: {
        type: String,
        attribute: "user-id"
      },
      hasSuitableMeansAgreement: {
        type: Boolean,
        state: true
      },
      _crontoImageExpired: {
        type: Boolean,
        attribute: false
      },
      _activationProgress: {
        type: Boolean,
        attribute: false
      }
    };
  }

  constructor() {
    super();
    this._crontoImageExpired = false;
    this._activationProgress = false;
  }

  static get styles() {
    return styles;
  }

  get _serialNumberElement() {
    return this.shadowRoot.querySelector('#inputSerialNumber') || {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.addEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.removeEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  _handleCrontoImageAppear = () => {
    this._crontoImageExpired = false;
  }

  _handleCrontoImageExpired = () => {
    this._crontoImageExpired = true;
  }

  _serialNumberValidator = (_) => {
    const serialNumber = this._serialNumberElement.unmaskedValue;
    // Skip validation for test-device serials (only configured on non-PROD)
    if (SerialNumberMapperService.isTestDeviceSerial( serialNumber )) {
      return true;
    }
    return CheckDigitValidationService.validateScannerSerialNumberCheckDigit( serialNumber );
  }

  _resetValidationErrors() {
    this._serialNumberElement.invalid = false;
    this._serialNumberElement.errorMessage = INVALID_SERIALNUMBER_FORMAT_ERROR_MESSAGE;
  }

  _setErrorResponseCode(errorMessage) {
    this._serialNumberElement.invalid = true;
    this._serialNumberElement.errorMessage = errorMessage;
  }

  async _handleNext() {
    this._clearTechnicalError();
    this._resetValidationErrors();
    const serialNumberElement = this._serialNumberElement;
    const isSerialNumberValid = serialNumberElement.validate();
    if (isSerialNumberValid) {
      this._activationProgress = true;
      const serialNumber = serialNumberElement.unmaskedValue;
      // For the test scanner devices no serial numbers are available, so we map them to device licences (only configured on non-PROD)
      if (SerialNumberMapperService.isTestDeviceSerial(serialNumber)) {
        const testDeviceLicence = SerialNumberMapperService.mapScannerSerial(serialNumber);
        this._handleSetupSerialNumber( testDeviceLicence );
      } else {
        this._handleSetupSerialNumber( serialNumber );
      }
    } else {
      console.error("Validation failed!");
    }
  }

  handleSetupSerialNumberError(error) {
    this._activationProgress = false;
    const status = error?.response?.status;

    if (status === 500) {
      this._setErrorResponseCode(INTERNAL_SERVER_ERROR_MESSAGE);
    } else {
      this.handleError(BmgFeatScannerSerialNumber.is, error);
    }
  }

  _handleSetupSerialNumber(serialNumber) {
    CrontoHardwareTokenService.requestScannerImage(
        crontoTypes.enrolDevice.token,
        { serialNumber: serialNumber }
    ).then(() => {
      this.fireCustomEvent(BmgFeatScannerSerialNumber.is + '-success', {
        serialNumber: serialNumber,
      });
    }).catch((err) => {
      this.handleSetupSerialNumberError(err);
    });
  }

  _renderCrontoImage() {
    return html`
        <bmg-feat-scanner-cronto-image
                cronto-type="serialNumber"
        ></bmg-feat-scanner-cronto-image>
    `;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">2. Connect your scanner</div>
        <bmg-form id="bmgForm" name="form">
            <ul class="info-bullets">
                <li>Put in the batteries that came with your scanner.</li>
                <li>Turn on your scanner and scan the color code below.</li>
            </ul>
            
            ${ this._renderCrontoImage() }

            <ul class="info-bullets">
                <li>
                  Your scanner now shows a serial number. Enter it below, then click 'Next'.
                </li>
            </ul>
            <div class="form-container">
                <amwa-masked-input
                        id="inputSerialNumber"
                        name="serialNumber"
                        label="Serial number"
                        autofocus
                        required
                        minLength="12"
                        maxLength="12"
                        mask="{38}-0000000-0"
                        .validator="${ this._serialNumberValidator }"
                        value="38-"
                        .disabled="${ this._crontoImageExpired }"
                        .errorMessage="${ INVALID_SERIALNUMBER_FORMAT_ERROR_MESSAGE }"
                ></amwa-masked-input>
            </div>
            <bmg-button class="button" .disabled="${ this._crontoImageExpired  || this._activationProgress }" @click=${this._handleNext}>Next</bmg-button>
            <bmg-button class="right" flat inverted @click=${this._openHelpDialog}>Need help?</bmg-button>
            <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>
    `;
  }
}