import { css, html, LitElement } from 'lit';
import './pages/login-choice-page';
import './pages/scanner-logon-page';
import './pages/activate-scanner-page';
import { AmwaHeader } from './components/amwa-header';
import { AmwaFooter } from './components/amwa-footer';
import { Router } from '@vaadin/router';
import { goToMobileLogon, goToPasswordLogon, goToScannerLogon, goToTokenLogon } from './util/navigate';
import { amwaApplicationStyle } from './amwa-application-style';
import '@bmg-web/bmg-alert';
import { ScopedElementsMixin } from "@open-wc/scoped-elements";
import { COOKIE_KEY, getCookieValue } from "./util/cookie-service";
import { AppSettings } from "./util/app-settings";


const SCANNER_PATHS = [ '/logon/scanner', '/activate-scanner' ];

class AmwaApp extends ScopedElementsMixin(LitElement) {

    static get scopedElements() {
      return {
        "amwa-header": AmwaHeader,
        "amwa-footer": AmwaFooter,
      };
    }

    static get properties() {
        return {
            router: {type: Object},
            location: {type: Object},
            appSettings: {type: String},
            lastLogonType: {type: String},
        };
    }

    constructor() {
        super();

        this._handleLastlogonType();
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`

            .container {
                  min-height: 100%;
                  display: flex;
                  flex-direction: column;
                  background-size: cover;
            }
            
            .container.summer {
                  background-image: url("/bmg-access-means/v1/static/image/bmg-login-bg-summer-30-opacity.jpg");
            }

            .container.winter {
                  background-image: url("/bmg-access-means/v1/static/image/bmg-login-bg-winter-30-opacity.jpg");
            }

            .main {
                flex-grow: 1;
                align-items: center;
                gap: 20px 20px;
                max-width: 866px;
                margin-left: auto;
                margin-right: auto;
                padding: 32px 0;
            }

            .sessionExpiredAlert {
                margin-top: 16px;
            }
            
            amwa-footer {
                flex-shrink: 0;
            }

            `,
        ];
    }

    connectedCallback() {
        AppSettings.initWithJsonString( this.appSettings );
        super.connectedCallback();
    }

    firstUpdated() {
        this.initRouter();
    }

    initRouter() {
        this.router = new Router(this.shadowRoot.querySelector('main'));
        const {router} = this;

        router.setRoutes([
          { path: '/',                       component: 'login-choice-page'     },
          { path: '/pages/logon/scanner',    component: 'scanner-logon-page'    },
          { path: '/pages/activate-scanner', component: 'activate-scanner-page' },
          { path: '(.*)',                    component: 'login-choice-page'     },
        ])
        .catch(console.error);
    }

    _handleLastlogonType() {
        this.lastLogonType = getCookieValue(COOKIE_KEY.LOGON_TYPE);
        if (this.lastLogonType && !this.isScannerPage()) {
            if ('password' === this.lastLogonType) {
                goToPasswordLogon();
            } else if ('token' === this.lastLogonType) {
                goToTokenLogon();
            } else if ('mobile' === this.lastLogonType) {
                goToMobileLogon();
            } else if ('scanner' === this.lastLogonType) {
                goToScannerLogon();
            }
        }
    }

    _shouldRedirectToOldAmwa() {
        // if we are on a scanner page, we don't re-direct to old amwa
        if (this.isScannerPage()) {
            return false;
        }

        // all logon types except scanner are handled by old amwa
        return (!!this.lastLogonType && this.lastLogonType !== 'scanner');
    }

    render() {
        const m = new Date().getMonth() + 1;
        const bgClassName = (m > 1 && m < 12) ? 'summer' : 'winter';
        // Check for needed redirection. Otherwise, this page gets rendered while it redirects to another screen.
        if (this._shouldRedirectToOldAmwa()) {
            return  html``;
        }
        return html`
            <div class="container ${bgClassName}">
                <amwa-header></amwa-header>
                <div class="main">
                    <main id="main" role="main" class="bsMainContent"><!-- This is where the Vaadin router injects content --></main>
                    <bmg-alert class="sessionExpiredAlert" type="info" ?hidden="${!this.showSessionExpiredAlert()}">
                                    Your session has expired, you need to logon again.
                    </bmg-alert>
                </div>
                <amwa-footer></amwa-footer>
            </div>
        `;
    }

    showSessionExpiredAlert() {
        return window.location.href.includes("sessionExpired");
    }

    isScannerPage() {
        return SCANNER_PATHS.find( p => window.location.pathname.includes(p) );
    }

}

customElements.define('amwa-app', AmwaApp);
