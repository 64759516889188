
const JSON_STRING_REGEX = /^\{.+}$/;
export const APP_SETTINGS_INITIALIZED_EVENT = 'app-settings-initialized';

export class AppSettings {

    static _appSettings = {
        bmgApiTimeout: 5_000,
        apiGatewayTimeout: 10_000,
        scannerSerialNumbersMap: new Map(),
    };

    static initWithJsonString = (jsonString) => {
        if (!JSON_STRING_REGEX.test(jsonString)) {
            return;
        }

        const parsed = JSON.parse(jsonString);

        if (!!parsed['scannerSerialMapping']) {
            parsed.scannerSerialNumbersMap = new Map(Object.entries(parsed['scannerSerialMapping']));
            delete parsed['scannerSerialMapping'];
        }

        Object.assign(this._appSettings, parsed);

        window.dispatchEvent(new Event(APP_SETTINGS_INITIALIZED_EVENT));
    }

    static get bmgApiTimeout() {
        return this._appSettings.bmgApiTimeout;
    }

    static get apiGatewayTimeout() {
        return this._appSettings.apiGatewayTimeout;
    }

    static get scannerSerialNumbersMap() {
        return this._appSettings.scannerSerialNumbersMap;
    }
}